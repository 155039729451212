<template>
   <div class="grid">
      <Toast />
      <ConfirmDialog></ConfirmDialog>
      <div class="col-12">
         <div class="card">
            <DataTable
               ref="dt"
               :value="locations"
               dataKey="id"
               :paginator="true"
               :rows="10"
               :filters="filters"
               :loading="loading"
               responsiveLayout="scroll"
            >
               <template #header>
                  <div
                     class="
                        table-header
                        p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                     "
                  >
                     <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                        Manage Locations
                     </h5>
                     <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                           v-model="filters['global'].value"
                           placeholder="Search..."
                        />
                     </span>
                  </div>
               </template>

               <Column field="id" header="ID"></Column>
               <Column field="state.name" header="Parent"></Column>
               <Column field="name" header="Child"></Column>
               <Column :exportable="false" style="min-width: 6rem">
                  <template #body="{ data }">
                     <Button
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-success mr-2"
                        @click="editLocation(data)"
                     />
                     <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-warning"
                        @click="confirmDeleteLocation(data.id)"
                     />
                  </template>
               </Column>
            </DataTable>
         </div>
         <Dialog
            v-model:visible="locationDialog"
            :style="{ width: '450px' }"
            header="Location Details"
            :modal="true"
            class="p-fluid"
         >
            <div class="field" v-if="location.parent != null">
               <label for="parent" class="mb-3">Parent</label>
               <Dropdown
                  id="parent"
                  v-model="location.parent"
                  :options="parent"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="--- Select a Parent ---"
               >
               </Dropdown>
            </div>

            <div class="field">
               <label for="name">Name</label>
               <InputText
                  id="name"
                  v-model="location.name"
                  required="true"
                  autofocus
               />
            </div>

            <template #footer>
               <Button
                  label="Cancel"
                  icon="pi pi-times"
                  class="p-button-text"
                  @click="hideDialog"
               />
               <Button
                  label="Save"
                  icon="pi pi-check"
                  class="p-button-text"
                  @click="saveLocation"
               />
            </template>
         </Dialog>
      </div>
   </div>
</template>

<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

export default {
   name: "Index",
   data() {
      return {
         locations: null, //lists array
         location: {},
         parent: {},
         filters: {},
         locationDialog: false,
         loading: true,
      };
   },
   methods: {
      ...mapActions([
         "location/fetchAllLocations",
         "location/update",
         "location/fetchParentLocations",
         "location/delete",
      ]),
      getAllLocations() {
         this["location/fetchAllLocations"]()
            .then((response) => {
               this.locations = response;
               this.loading = false;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      getParentLocations() {
         this["location/fetchParentLocations"]()
            .then((response) => {
               this.parent = response;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      hideDialog() {
         this.locationDialog = false;
      },
      initFilters() {
         this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
         };
      },
      editLocation(data) {
         this.locationDialog = true;
         this.location = { ...data };
      },
      saveLocation() {
         const inputData = this.location;
         console.log(inputData);
         this["location/update"](inputData)
            .then(() => {
               this.loading = true;
               this.locationDialog = false;
               this.getAllLocations();

               this.$toast.add({
                  severity: "success",
                  summary: "Confirmed",
                  detail: "Successfully updated.",
                  life: 3000,
               });
            })
            .catch((error) => {
               console.log(error);
            });
      },
      confirmDeleteLocation(id) {
         this.$confirm.require({
            message: "Are you sure you want to proceed?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               this.$toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "Successfully deleted.",
                  life: 3000,
               });
               this["location/delete"](id)
                  .then(() => {
                     this.loading = true;
                     this.getAllLocations();
                     console.log(id);
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
   },
   created() {
      this.getAllLocations();
      this.getParentLocations();
      this.initFilters();
   },
};
</script>

<style lang="scss" scoped>
.table-header {
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 960px) {
      align-items: start;
   }
}
</style>
